import { StyledLayout, CoreContent } from "./Layout.Styled";
// import Header from "./Header/Header";
import Footer from "./Footer/Footer";
// import Secure from "./Secure/Secure";

const Layout = ({
  children,
  className,
  hideHeader = false,
  hideFooter = false,
  // authRequired = true,
  // requiredLevel = null,
}) => {
  return (
    <StyledLayout className={className}>
      {/* <Secure authRequired={authRequired} requiredLevel={requiredLevel}> */}
      {/* {!hideHeader && <Header />} */}
      <CoreContent hideHeader={hideHeader}>{children}</CoreContent>
      {!hideFooter && <Footer />}
      {/* </Secure> */}
    </StyledLayout>
  );
};

export default Layout;
