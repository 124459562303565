import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  StyledVision,
  Headline,
  Boxes,
  Box,
  Title,
  Text,
} from "./Vision.Styled";

const Vision = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledVision>
      <Container>
        <Headline>{translation.home.vision.headline}</Headline>
        <Boxes>
          {translation.home.vision.boxes.map((b) => (
            <Box key={b.title}>
              <Title>{b.title}</Title>
              <Text>{b.text}</Text>
            </Box>
          ))}
        </Boxes>
      </Container>
    </StyledVision>
  );
};

export default Vision;
