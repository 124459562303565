import { StyledIntro, Headline, Sublines, Subline } from "./Intro.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import { Container } from "@think-internet/ui-components";

const Intro = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledIntro>
      <Container>
        <Headline>{translation.home.intro.headline}</Headline>
        <Sublines>
          {translation.home.intro.sublines.map((s) => (
            <Subline key={getUUID()}>
              {Array.isArray(s)
                ? s.map((t) => <div key={getUUID()}>{t}</div>)
                : s}
            </Subline>
          ))}
        </Sublines>
      </Container>
    </StyledIntro>
  );
};

export default Intro;
