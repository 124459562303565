import { StyledFooter, Flex, Text, Link } from "./Footer.Styled";
import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const getCurrentFullYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <StyledFooter>
      <Container>
        <Flex justify={"right"}>
          <Link
            href="https://www.jade-concept.de/imprint"
            target="_blank"
            rel="noreferrer"
          >
            {translation.imprint.headline}
          </Link>
          <Link
            href="https://www.jade-concept.de/data-protection"
            target="_blank"
            rel="noreferrer"
          >
            {translation.dataProtection.headline}
          </Link>
          <Text>&copy;{` ${getCurrentFullYear()} `}</Text>
        </Flex>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
