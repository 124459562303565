import {
  StyledTech,
  BackgroundImages,
  BGImage,
  Content,
  Split,
  Item,
  Environment,
  Headline,
  Subline,
  Prerequirements,
  Texts,
  Text,
  Header,
  CardSplit,
  Card,
  CardHeader,
  CardIcon,
  CardTitle,
  CardText,
  Important,
} from "./Tech.Styled";
import energy from "../../../assets/image/home/tech/energy.webp";
import solar from "../../../assets/image/home/tech/solar.webp";
import windIcon from "../../../assets/image/home/tech/windIcon.svg";
import solarIcon from "../../../assets/image/home/tech/solarIcon.svg";
import waterIcon from "../../../assets/image/home/tech/waterIcon.svg";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { Container } from "@think-internet/ui-components";
import { getUUID } from "../../../utility";

const Tech = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledTech>
      <BackgroundImages>
        <BGImage src={energy} />
        <BGImage src={solar} direction={"right"} />
      </BackgroundImages>
      <Content>
        <Container>
          <Split>
            <Item width="40%">
              <Environment>
                <Headline>
                  {translation.home.tech.environment.headline}
                </Headline>
                <Texts>
                  {translation.home.tech.environment.texts.map((text) => (
                    <Text key={getUUID()}>{text}</Text>
                  ))}
                </Texts>
              </Environment>
            </Item>
            <Item width="60%">
              <Prerequirements>
                <Header>
                  <Headline>
                    {translation.home.tech.prerequirements.headline}
                  </Headline>
                  <Subline>
                    {translation.home.tech.prerequirements.subline}
                  </Subline>
                </Header>
                <CardSplit>
                  <Card>
                    <CardHeader>
                      <CardIcon src={windIcon} />
                      <CardTitle color="torquoise">
                        {translation.home.tech.prerequirements.wind.title}
                      </CardTitle>
                    </CardHeader>
                    <CardText>
                      {translation.home.tech.prerequirements.wind.text}
                    </CardText>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardIcon src={solarIcon} />
                      <CardTitle color="orange">
                        {translation.home.tech.prerequirements.solar.title}
                      </CardTitle>
                    </CardHeader>
                    <CardText>
                      {translation.home.tech.prerequirements.solar.text}
                    </CardText>
                  </Card>
                </CardSplit>
                <Card>
                  <CardHeader>
                    <CardIcon src={waterIcon} />
                    <CardTitle>
                      {translation.home.tech.prerequirements.water.title}
                    </CardTitle>
                  </CardHeader>
                  <CardText>
                    {translation.home.tech.prerequirements.water.text}
                    <Important>
                      <span className="bold">
                        {translation.home.tech.prerequirements.water.important +
                          " "}
                      </span>
                      {
                        translation.home.tech.prerequirements.water
                          .importantText
                      }
                    </Important>
                  </CardText>
                </Card>
              </Prerequirements>
            </Item>
          </Split>
        </Container>
      </Content>
    </StyledTech>
  );
};

export default Tech;
