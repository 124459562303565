import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Triangle from "../../SubComponents/Triangle/Triangle";
import {
  Content,
  StyledContact,
  Headline,
  Split,
  Item,
  Text,
  Link,
} from "./Contact.Styled";

const Contact = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledContact>
      <Triangle backgroundColor={"rgb(88,167,175)"} />
      <Content>
        <Container>
          <Headline>{translation.home.contact.headline}</Headline>
          <Split>
            <Item>
              {translation.genericInfo.companyName.map((cN) => (
                <Text right bold key={cN}>
                  {cN}
                </Text>
              ))}
              <Text right>{translation.genericInfo.street}</Text>
              <Text right>{translation.genericInfo.city}</Text>
            </Item>
            <Item>
              <Text bold>{translation.genericInfo.members.initia.name}</Text>
              <Link
                href={
                  "https://" + translation.genericInfo.members.initia.homepage
                }
                target="_blank"
                rel="noreferrer"
              >
                {translation.genericInfo.members.initia.homepage}
              </Link>
              <Text marginTop="10px" bold>
                {translation.genericInfo.members.jade.name}
              </Text>
              <Link
                href={
                  "https://" + translation.genericInfo.members.jade.homepage
                }
                target="_blank"
                rel="noreferrer"
              >
                {translation.genericInfo.members.jade.homepage}
              </Link>
            </Item>
          </Split>
        </Container>
      </Content>
    </StyledContact>
  );
};

export default Contact;
