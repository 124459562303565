import {
  StyledWhoWeAre,
  Introduction,
  Headline,
  Texts,
  Text,
  Companies,
  Company,
  Image,
  Float,
  BackgroundMobileImage,
} from "./WhoWeAre.Styled";
import image from "../../../assets/image/home/whoWeAre/image.webp";
import jade from "../../../assets/image/generic/jade.svg";
import initia from "../../../assets/image/generic/initia.svg";
import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";

const WhoWeAre = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledWhoWeAre image={image}>
      <Container>
        <Float>
          <Introduction>
            <Headline>{translation.home.whoWeAre.headline}</Headline>
            <Texts>
              {translation.home.whoWeAre.texts.map((t) => (
                <div key={getUUID()}>{t}</div>
              ))}
            </Texts>
          </Introduction>
        </Float>
        <BackgroundMobileImage src={image} />
        <Float direction={"right"}>
          <Companies>
            <Company>
              <Image image={jade} />
              <Text>{translation.home.whoWeAre.jade}</Text>
            </Company>
            <Company>
              <Image image={initia} />
              <Text>{translation.home.whoWeAre.initia}</Text>
            </Company>
          </Companies>
        </Float>
      </Container>
    </StyledWhoWeAre>
  );
};

export default WhoWeAre;
