import { Image, StyledVisual } from "./Visual.Styled";
import image from "../../../assets/image/home/visual/image.webp";
import { Container } from "@think-internet/ui-components";

const Visual = () => {
  return (
    <StyledVisual>
      <Container>
        <Image src={image} />
      </Container>
    </StyledVisual>
  );
};

export default Visual;
