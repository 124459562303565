import styled from "styled-components";

export const StyledVision = styled.div`
  padding: 150px 0;
`;

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.torquoise};
  text-align: center;
  font-family: "bold";
`;

export const Boxes = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 100px;
`;
export const Box = styled.div`
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 30px;
  box-sizing: border-box;
  width: calc(50% - 30px / 2);
  background-color: ${({ theme }) => theme.color.background.lightblue};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;
export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.torquoise};
  font-family: "bold";
`;
export const Text = styled.div`
  margin-top: 10px;
  font-size: ${({ theme }) => theme.font.size.sm};
`;
