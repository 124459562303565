import styled from "styled-components";

export const StyledHero = styled.div`
  width: 100%;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  height: calc(100vh + 125px);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: calc(100vh + 25px);
  }
`;

export const Logo = styled.img`
  height: 100px;
  margin-top: 20vh;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 75px;
  }
`;

export const Headline = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xlg};
  margin-top: 50px;
  font-family: "bold";
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;
export const Subheadline = styled.h2`
  color: ${({ theme }) => theme.color.font.torquoise};
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: "bold";
  font-size: 40px;
  margin-top: 10px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;

export const TriangleWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
