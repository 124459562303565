import styled from "styled-components";

export const StyledIntro = styled.div`
  padding: 100px 0;
`;

export const Headline = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.font.torquoise};
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: "bold";
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;
export const Sublines = styled.div``;
export const Subline = styled.div`
  margin-top: 25px;
  text-align: center;
`;
