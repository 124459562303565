//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  home: {
    hero: {
      headline: "GLOBALE HERAUSFORDERUNGEN ANNEHMEN",
      subheadline: "REGIONALE LÖSUNGEN ENTWICKELN!",
    },
    intro: {
      headline: "DER ENERGIEMARKT ORDNET SICH NEU",
      sublines: [
        [
          "Nutzen Sie mit uns die Chancen einer völlig neuartigen Energieversorgung:",
          "dezentral mit regionalen Partnern, klimaneutral und effizient",
        ],
        "Mit der Verpachtung Ihrer Flächen zur Errichtung einer Photovoltaik- oder Wind-kraftanlage nehmen Sie aktiv an der Energiewende teil und profitieren dabei von einer lukrativen Rendite.",
      ],
    },
    whoWeAre: {
      headline: "DAS SIND WIR",
      texts: [
        "Die WSW Erneuerbare Energien Beteiligungs GmbH & Co. KG ist einZusammenschluss der Jade Concept GmbH und der 4initia GmbH.",
        "Die Jade Concept GmbH bietet Ihnen langfristige Erfahrung in der Projektierung und Betreuung von Windenergieanlagen.",
        "Auf der anderen Seite steht die 4initia GmbH mit weitreichenden Kompetenzen im Bereich der Photovoltaikanlagen.",
        "Durch diesen Zusammenschluss können wir alle erforderlichen Bereiche abdecken und verfügen über ein umfassendes Know How im Bereich der erneuerbaren Energien. Ihnen steht ein junges und dynamisches Team zur Seite damit wir für die globale Herausforderung gemeinsam eine regionale Lösung erarbeiten können.",
      ],
      jade: "Ihr Partner für die Energiewende in Norddeutschland. Seit 1999 entwickeln und betreuen wir eigentümergeführte Windparks und PV-Anlagen.",
      initia:
        "Seit 2012 ein verlässlicher Partner im Bereich der Genehmigunsplanungen und technischen Betriebsführungen von Wind- und PV-Anlagen",
    },
    advantages: {
      headline: "VORTEILE FÜR DEN VERPÄCHTER",
      bulletpoints: [
        {
          title: "EINFACH",
          text: "Nach Ende der Laufzeit der Module können diese einfach demontiert werden, Sicherung ist durch eine Rückbaubürgschaft gewährleistet",
        },
        {
          title: "SICHER",
          text: "Sichere Pacht über die komplette Laufzeit (mindestens 20 Jahre) jährliche Zahlung",
        },
        {
          title: "FLEXIBEL",
          text: "Flexible Anlagengröße durch modularen Aufbau",
        },
        {
          title: "KONFLIKTARM",
          text: "Konfliktarme Bewirtschaftung nahe Siedlungsgebieten",
        },
      ],
    },
    vision: {
      headline: "VON DER VISION ZUM KONKRETEN PLAN",
      boxes: [
        {
          title: "DIE HERAUSFORDERUNG",
          text: "... ist eindeutig - zum Schutz des Klimas und der Zukunftschancen der folgenden Generationen werden wir auf fossile Brennstoffe wie Öl und Gas verzichten müssen. Der Anfang ist gemacht: 2020 waren 20% des Energiebedarfs erneuerbar! Spätestens bis 2045 wird die Erzeugung und der Verbrauch unserer Energie vollständig zu 100% klimaneutral und damit nachhaltig sein. Dafür gilt es den übrigen Energiebedarf sektorenübergreifend komplett klimaneutral zu entwickeln.",
        },
        {
          title: "DER PLAN",
          text: "... ist, den grünen Wasserstoff in eine regionale Infrastruktur innovativ und effizient zu integrieren. Idealerweise soll die gewonnene Energie speziell den regional ansässigen Wirtschaftsbetrieben bereitgestellt werden. Als Partner kommen hierfür unter anderem öffentliche Verkehrsträger, Logistik- und Industrieunternehmen in Betracht.",
        },
        {
          title: "UNSERE VISION",
          text: "... ist, die nachhaltige Stromerzeugung aus Wind- und Solarenergie zusammen zu führen und damit Wasserstoffanlagen zu betreiben. Mit einem Mix aus Photovoltaik- und Windenergie wird es möglich, ganzjährig mit Hilfe eines Elektrolyseurs Wasserstoff herzustellen. Dadurch wird der gewonnene Strom in speicher- und nutzbare Energie für Verkehr und Wärme umgewandelt. So wird die Energiewende machbar: In Verantwortung für kommende Generationen und effizient für die Teilhaber.",
        },
        {
          title: "IHR BEITRAG",
          text: "Um diese Vision zu verwirklichen, werden Flächen benötigt, die sich zur die sich zur Errichtung von Photovoltaikanlagen eignen. Zu finden sind diese Flächen meist an Autobahnlinien und Schienenwegen. Alternativ können auch Freiflächen ab einer bestimmten Größe genutzt werden. Sie als Verpächter können aktiv an der Energiewende teilnehmen und dabei von einer lukrativen Pacht profitieren. Aber auch für Betreiber von „Altwindenergieanlagen“, die ihren Strom direkt vermarkten müssen, können sich hierdurch interessante Perspektiven für einen Weiterbetrieb ergeben.",
        },
      ],
    },
    tech: {
      environment: {
        headline: "UMWELTSCHUTZ",
        texts: [
          "Mit Hilfe des Grünen Wasserstoffs ist es möglich,die größten Treihausgas- Verursacher klimafreundlich umzugestalten und gleichzeitig den Technologiestandort Deutschland zu stärken.Grüner Wasserstoff kann als Kraftstoff im Verkehr eingesetzt werden und ist zudem speicherbar.",
          "Darüber hinaus bieten sich vielfache Möglichkeiten, effektive Beiträge zum Schutz der Biodiversität zu leisten.",
        ],
      },
      prerequirements: {
        headline: "TECHNIK //",
        subline: "VORAUSSETZUNGEN",
        wind: {
          title: "Windenergie",
          text: "Photovoltaik und Windenergie ergänzen sich durch ihre individuellen Schwerpunkte in der Stromerzeugung (Winter/Sommer). Dadurch kann ganzjährig Wasserstoff erzeugt werden.",
        },
        solar: {
          title: "Solarenergie",
          text: "Photovoltaik und windenergie ergänzen sich durch ihre individuellen Schwerpunkte in der Stromerzeugung (Winter/Sommer). Dadurch kann ganzjährig Wasserstoff erzeugt werden.",
        },
        water: {
          title: "Wasserstoff",
          text: "Mit einer 225 kW Elektrolyse-Anlage können ca. 100 kg Wasserstoff am Tag erzeugt werden. Damit lassen sich 750 km Busverkehr oder 1.000 km LKW Verkehr versorgen. Chemisch enthält 100kg Wasserstoff eine Energiemenge von 3.333 Kilowattstunden. So viel verbraucht im Durchschnitt ein 3-Personen-Haushalt in einem Mehrfamilienhaus.",
          important: "Wichtig:",
          importantText:
            "chemische Energie kann nicht zu 100 Prozent in nutzbare Energie umgewandelt werden.",
        },
      },
    },
    contact: {
      headline: "KONTAKT",
    },
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  gtc: {
    headline: "AGB",
  },

  genericInfo: {
    companyName: ["WSW Erneuerbare Energien", "Beteiligungs GmbH & Co. KG"],
    street: "Friedrich-Ebert-Straße 28",
    city: "26316 Varel",
    members: {
      jade: {
        name: "Jade Concept GmbH",
        homepage: "www.jade-gruppe.de",
      },
      initia: {
        name: "4initia GmbH",
        homepage: "newsletter.4initia.de",
      },
    },
  },

  generic: {
    salutation: "Anrede",
    title: "Titel",
    name: "Name",
    firstname: "Vorname",
    lastname: "Nachname",
    address: "Adresse",
    street: "Straße",
    postcode: "Postleitzahl",
    city: "Stadt",
    phone: "Telefon",
    fax: "Fax",
    mail: "E-Mail",
    password: "Passwort",
    km: " km",
    save: "Speichern",
    view: "Einsehen",
    remove: "Löschen",
    create: "Erstellen",
    message: "Nachricht",
    description: "Beschreibung",

    level: {
      ADMIN: "Administator",
      MANAGER: "Manager",
      USER: "Benutzer",
    },
  },

  login: {
    headline: "Login",
    cta: "Einloggen",
    error: "E-Mail Adresse oder Passwort falsch.",
  },

  UI: {
    dropdown: {
      emptySelection: "Bitte wählen",
    },
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
