import styled from "styled-components";

export const StyledTech = styled.div`
  margin: 150px 0;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 0px;
  }
`;

export const BackgroundImages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 75px;
`;
export const BGImage = styled.img`
  width: 75%;
  display: block;
  ${({ direction }) => direction === "right" && "margin-left: auto;"}
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    position: static;
  }
`;
export const Split = styled.div`
  display: flex;
  gap: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;
export const Item = styled.div`
  width: ${({ width }) =>
    width ? `calc(${width} - 37.5px)` : "calc(50% - 37.5px)"};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;
export const Environment = styled.div`
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: ${({ theme }) => theme.color.background.white};
  padding: 30px;
  margin-top: 90%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 150px;
  }
`;
export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.torquoise};
  font-family: "bold";
`;
export const Subline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.blue};
  font-family: "bold";
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;
export const Prerequirements = styled.div``;

export const Header = styled.div`
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: ${({ theme }) => theme.color.background.white};
  padding: 15px;
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
`;

export const CardSplit = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;
export const Card = styled.div`
  padding: 15px 15px 30px 15px;
  background-color: ${({ theme }) => theme.color.background.white};
  box-shadow: ${({ theme }) => theme.shadow};
  margin-top: 10px;
`;
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
`;
export const CardIcon = styled.img`
  width: 30px;
  max-height: 100%;
`;
export const CardTitle = styled.div`
  text-transform: uppercase;
  font-family: "bold";
  color: ${({ theme, color }) => theme.color.font[color]};
`;
export const CardText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-left: 40px;
  margin-top: 10px;
`;

export const Important = styled.div`
  margin-top: 15px;
  font-size: ${({ theme }) => theme.font.size.sm};
  span {
    font-size: ${({ theme }) => theme.font.size.sm};
    font-family: "bold";
  }
`;
