import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  StyledAdvantages,
  Headline,
  Bulletpoints,
  Bulletpoint,
  Title,
  Text,
} from "./Advantages.Styled";

const Advantages = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledAdvantages>
      <Container>
        <Headline>{translation.home.advantages.headline}</Headline>
        <Bulletpoints>
          {translation.home.advantages.bulletpoints.map((b, i) => (
            <Bulletpoint key={b.title} odd={i % 2 === 0}>
              <Title>{b.title}</Title>
              <Text>{b.text}</Text>
            </Bulletpoint>
          ))}
        </Bulletpoints>
      </Container>
    </StyledAdvantages>
  );
};

export default Advantages;
