const black = "rgb(63,63,63)";
const white = "rgb(255,255,255)";
const lightgray = "#cdcdcd";
const red = "rgb(228,0,49)";
const blue = "rgb(12,56,81)";
const torquoise = "rgb(88,167,175)";
const orange = "rgb(247,181,138)";
const lightblue = "rgb(220, 233, 237)";

const theme = {
  font: {
    size: {
      sm: "16px",
      md: "18px",
      lg: "24px",
      xlg: "34px",
      headline: "46px",
    },
  },
  color: {
    font: {
      primary: blue,
      secondary: white,
      headline: black,
      nav: black,
      hint: lightgray,
      red,
      blue,
      white,
      torquoise,
      orange,
    },
    background: {
      primary: white,
      secondary: black,
      red: red,
      white: white,
      torquoise,
      lightblue,
      blue,
    },
    border: {
      primary: black,
      secondary: white,
      lightgray,
    },
    availability: {
      AVAILABLE: "#7fff7b",
      LIMITED: "#fffe7d",
      UNAVAILABLE: "#f85259",
      default: "rgb(226,226,226)",
    },
    lighterBlue: "#a7d1f7",
    lightBlue: "#7daed2",
    darkBlue: "#648db4",

    red: "#e5192c",
  },
  breakpoint: {
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  border: {
    radius: "5px",
  },
  shadow: "1px 2px 15px -8px rgba(0, 0, 0, 0.75)",
};

const adjustedComponentStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: `
    background-color: transparent;
    border-radius: 5px;
    background-color: ${theme.color.background.white};
    border: none;
    &.danger {
      background-color: ${theme.color.background.red};
      color: ${theme.color.font.secondary};
      border: none;
    }
    `,
    anchor: ``,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: `
    box-sizing: border-box;
    border-radius: 0px;
    background-color: ${theme.color.background.white};
    border: none;
    `,
    title: ``,
    hint: ``,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: `margin-top: 10px;color: ${theme.color.font.darkBlue}; width: 100%;`,
    select: `border-radius: 15px;border: none;padding: 10px;color: ${theme.color.font.darkBlue};background-color: ${theme.color.background.white};`,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: ``,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: `
    border-radius: 15px;
    border: none;
    padding: 10px;
    color: ${theme.color.font.darkBlue};
    background-color: ${theme.color.background.white};
    `,
    wrapper: ``,
    label: ``,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: `
    `,
    background: `
    `,
    core: `
    `,
    header: `
    `,
    title: ``,
    close: ``,
    content: ``,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: `
    background-color: ${theme.color.background.white};
    border-radius: 15px;
    border: none;
    padding: 10px;
    color: ${theme.color.font.darkBlue}`,
    wrapper: ``,
    label: ``,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
};

const exportableTheme = { ...theme, components: adjustedComponentStyling };

export default exportableTheme;
