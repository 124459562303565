import styled from "styled-components";
import circle from "../../../assets/image/home/advantages/circle.svg";

export const StyledAdvantages = styled.div`
  padding: 150px 0;
`;

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.torquoise};
  font-family: "bold";
  text-align: center;
`;

export const Bulletpoints = styled.div`
  display: flex;
  margin-top: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 25px;
  }
`;
export const Bulletpoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 25%;
  background-image: url(${circle});
  background-repeat: no-repeat;
  background-size: 75px 75px;
  background-position: 50px 0px;
  padding-top: 25px;
  ${({ odd }) =>
    odd &&
    `
    margin-top: 150px;
  `}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    ${({ odd }) =>
      odd &&
      `
    margin-top: 0px;
  `}
  }
`;
export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.orange};
  font-family: "bold";
  text-align: center;
`;
export const Text = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
`;
