import styled from "styled-components";

export const StyledWhoWeAre = styled.div`
  background-image: url(${({ image }) => image});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-image: none;
  }
`;

export const Introduction = styled.div`
  padding: 50px;
  background-color: ${({ theme }) => theme.color.background.torquoise};
  box-shadow: ${({ theme }) => theme.shadow};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 20px;
  }
`;
export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: "bold";
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  * {
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.color.font.secondary};
  }
`;

export const Companies = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
`;
export const Company = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.background.torquoise};
  padding: 40px;
  box-shadow: ${({ theme }) => theme.shadow};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 20px;
  }
`;
export const Image = styled.div`
  width: 35%;
  height: 100px;
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
export const Text = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  width: 65%;
`;

export const Float = styled.div`
  width: 47.5%;
  &:last-child {
    margin-top: 100px;
  }
  ${({ direction }) => direction === "right" && "margin-left: auto;"}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    &:last-child {
      margin-top: 0;
    }
  }
`;

export const BackgroundMobileImage = styled.img`
  display: none;
  width: 100%;
  margin: 25px auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`;
