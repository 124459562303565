import { StyledHome, Separator } from "./Home.Styled";
import Layout from "../Layout/Layout";
import Hero from "./Hero/Hero";
import Intro from "./Intro/Intro";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import Advantages from "./Advantages/Advantages";
import separator from "../../assets/image/home/separator.webp";
import Vision from "./Vision/Vision";
import Tech from "./Tech/Tech";
import Visual from "./Visual/Visual";
import Contact from "./Contact/Contact";

const Home = () => {
  return (
    <Layout authRequired={false}>
      <StyledHome>
        <Hero />
        <Intro />
        <WhoWeAre />
        <Advantages />
        <Separator src={separator} />
        <Vision />
        <Tech />
        <Visual />
        <Contact />
      </StyledHome>
    </Layout>
  );
};

export default Home;
