import {
  StyledHero,
  Logo,
  Headline,
  Subheadline,
  TriangleWrapper,
} from "./Hero.Styled";
import WsW_Logo from "../../../assets/image/generic/wsw.svg";
import hero from "../../../assets/image/home/hero/image.webp";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Triangle from "../../SubComponents/Triangle/Triangle";

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledHero image={hero}>
      <Logo src={WsW_Logo} alt="Logo" />
      <Headline>{translation.home.hero.headline}</Headline>
      <Subheadline>{translation.home.hero.subheadline}</Subheadline>
      <TriangleWrapper>
        <Triangle />
      </TriangleWrapper>
    </StyledHero>
  );
};

export default Hero;
