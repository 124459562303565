import styled from "styled-components";

export const StyledContact = styled.div``;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.torquoise};
  padding: 75px 0 150px 0;
`;

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.white};
  font-family: "bold";
  text-align: center;
`;

export const Split = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  ${({ bold }) => bold && `font-family: "bold";`};
  color: ${({ theme }) => theme.color.font.white};
  text-align: ${({ right }) => (right ? "right" : "left")};
  margin-top: ${({ marginTop }) => marginTop || "5px"};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
  }
`;

export const Link = styled.a`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.white};
  text-decoration: none;
  display: block;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
  }
`;
