import { StyledTriangle } from "./Triangle.Styled";

// Triangle generator
// https://www.shapedivider.app/

const Triangle = ({ backgroundColor, reverse = false, width = "100%" }) => {
  return (
    <StyledTriangle
      reverse={reverse}
      width={width}
      backgroundColor={backgroundColor}
    >
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
          className="shape-fill"
        ></path>
      </svg>
    </StyledTriangle>
  );
};

export default Triangle;
