import styled from "styled-components";

export const StyledVisual = styled.div`
  padding: 150px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-top: 0px;
  }
`;

export const Image = styled.img`
  width: 100%;
`;
